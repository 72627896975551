import './Footer.scss';

const Footer = () => (
  <footer className='footer'>
    <p>
      Huseyn Akhundov | Updated 17.01.2023
    </p>
  </footer>
)

export default Footer;
